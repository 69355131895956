if ($('#plataforma-configuracion-informacion').length > 0) {

    var vmPlataformaConfiguracionInformacion = new Vue({
        el: '#plataforma-configuracion-informacion',

        data: {
            informacion: {
                nombre: '', descripcion: '', horario: '', telefono_principal: '', telefonos: '', direccion: '', email: '', facebook: '', twitter: '', instagram: ''
            },
            editar: {
                nombre: '', descripcion: '', horario: '', telefono_principal: '', telefonos: '', direccion: '', email: '', facebook: '', twitter: '', instagram: ''
            },
        },

        created: function () {
            rellenarObjeto(this.informacion, informacion);
        },

        methods: {
            editarInformacion: function () {
                rellenarObjeto(this.editar, this.informacion);
                $('#txtEditarLogo').val('');
                $('#txtEditarFondo').val('');
                $('#txtEditarCabecera').val('');
                $('#editarInformacionModal').modal('show');
            },

            guardarInformacion: function () {
                var self = this;

                var formData = new FormData();
                formData.append('_method', 'PUT');
                for (var propiedad in self.editar) {
                    formData.append(propiedad, self.editar[propiedad]);
                }
                formData.append('logo', $('#txtEditarLogo')[0].files[0]);
                formData.append('fondo', $('#txtEditarFondo')[0].files[0]);
                formData.append('cabecera', $('#txtEditarCabecera')[0].files[0]);

                ajax_archivo('/plataforma/configuracion/informacion/actualizar', 'json', formData, $('#btnEditarInformacion'), function (informacion) {
                    rellenarObjeto(self.informacion, informacion);
                    $('#editarInformacionModal').modal('hide');
                    $('#imgLogo').attr('src', '/img/logo.png?random=' + new Date().getTime());
                    $('#imgFondo').attr('src', '/img/bg.jpg?random=' + new Date().getTime());
                    $('#imgCabecera').attr('src', '/img/cabecera.jpg?random=' + new Date().getTime());
                    toastr.info('La informacion fue actualizada satisfactoriamente.', 'INFORMACIÓN');
                });
            },
        }
    });

    $('#editarInformacionModal').on('shown.bs.modal', function () {
        $('#txtEditarNombre').focus();
    });

}
