if ($('#plataforma-website-colores').length > 0) {

    var editor = null;

    var vmPlataformaWebsiteColores = new Vue({
        el: '#plataforma-website-colores',

        data: {
            editar: {
                _method: 'PUT', contenido: '',
            }
        },

        mounted: function () {
            editor = CodeMirror.fromTextArea($('#txtEditarContenido')[0], {
                lineNumbers: true,
                mode: 'css',
                theme: 'material'
            });
            editor.setSize("100%", 400);
        },

        methods: {
            guardarColores: function () {
                var self = this;
                self.editar.contenido = editor.getValue();
                ajax('/plataforma/website/colores/actualizar', 'html', self.editar, $('#btnEditarColores'), function () {
                    toastr.info('La información fue actualizada satisfactoriamente.', 'COLORES');
                });
            },
        }
    });

}
