if ($('#plataforma-website-calendario').length > 0) {

    var vmPlataformaWebsiteCalendario = new Vue({
        el: '#plataforma-website-calendario',

        data: {
            areas: [],
            eventos: [],
            filtros: {
                area_id: 0,
            },
            crear: {
                area_id: 0, titulo: '', fecha_inicio: '', misma_fecha: 1, fecha_fin: '', observaciones: ''
            },
            duplicar: {
                area_id: '', titulo: '', fecha_inicio: '', fecha_fin: '', observaciones: ''
            },
            editar: {
                _method: 'PUT', id: '', titulo: '', area_id: '', fecha_inicio: '', fecha_fin: '', observaciones: ''
            },
            temporal: {},
            eliminar: {
                _method: 'DELETE', id: ''
            },
        },

        created: function () {
            this.eventos = eventos;
            this.areas = areas;
        },

        computed: {
            eventosFiltrados: function () {
                var self = this;
                return self.eventos.filter(function (evento) {
                    return parseInt(self.filtros.area_id) === 0 || parseInt(evento.area_id) === parseInt(self.filtros.area_id);
                });
            },

            eventosOrdenados: function () {
                return this.eventosFiltrados.sort(function (a, b) {
                    return new Date(a.fecha_inicio) - new Date(b.fecha_inicio);
                });
            },

            areasOrdenadas: function () {
                return this.areas.sort(function (a, b) {
                    return parseInt(a.numero) - parseInt(b.numero);
                });
            },
        },

        filters: {
            fechaFormateada: function (value) {
                return moment(value).format('DD/MMM/YYYY');
            },
        },

        methods: {
            crearEvento: function () {
                var self = this;
                ajax('/plataforma/website/calendario/almacenar', 'json', self.crear, $('#btnCrearEvento'), function (evento) {
                    self.eventos.push(evento);
                    $('#crearEventoModal').modal('hide');
                    limpiarObjeto(self.crear);
                    self.crear.misma_fecha = 1;
                    self.crear.area_id = 0;
                    toastr.success('El evento "' + evento.titulo + '" fue creado satisfactoriamente.', 'CALENDARIO');
                });
            },

            duplicarEvento: function (evento) {
                rellenarObjeto(this.duplicar, evento);
                this.$refs.duplicarEventoTitulo.textContent = evento.titulo;
                $('#duplicarEventoModal').modal('show');
            },

            guardarEventoDuplicado: function () {
                var self = this;
                ajax('/plataforma/website/calendario/duplicar', 'json', self.duplicar, $('#btnDuplicarEvento'), function (evento) {
                    self.eventos.push(evento);
                    $('#duplicarEventoModal').modal('hide');
                    toastr.success('El evento "' + evento.titulo + '" fue creado satisfactoriamente.', 'CALENDARIO');
                });
            },

            editarEvento: function (evento) {
                rellenarObjeto(this.editar, evento, ['_method']);
                this.temporal = evento;
                this.eliminar.id = evento.id;
                this.$refs.editarEventoTitulo.textContent = evento.titulo;
                $('#editarEventoModal').modal('show');
            },

            guardarEvento: function () {
                var self = this;
                ajax('/plataforma/website/calendario/actualizar', 'json', self.editar, $('#btnEditarEvento'), function (evento) {
                    rellenarObjeto(self.temporal, evento);
                    $('#editarEventoModal').modal('hide');
                    toastr.info('El evento "' + evento.titulo + '" fue actualizado satisfactoriamente.', 'CALENDARIO');
                });
            },

            eliminarEvento: function (event) {
                var self = this;
                if (confirm('¿Seguro(a) qué desea eliminar el evento "' + self.temporal.titulo + '"?')) {
                    ajax('/plataforma/website/calendario/eliminar', 'html', self.eliminar, $(event.currentTarget), function () {
                        removerObjeto(self.eventos, self.temporal);
                        $('#editarEventoModal').modal('hide');
                        toastr.warning('El evento "' + self.temporal.titulo + '" ha sido eliminado.', 'CALENDARIO');
                    });
                }
            },

            inicializarCalendario: function (event) {
                if (confirm('¿Seguro(a) qué desea remover todos los elementos del calendario?')) {
                    var self = this;
                    ajax('/plataforma/website/calendario/inicializar', 'html', {}, $(event.currentTarget), function () {
                        self.eventos = [];
                        toastr.info('El calendario ha sido inicializado satisfactoriamente.', 'CALENDARIO');
                    });
                }
            },
        }
    });

    $('#crearEventoModal').on('shown.bs.modal', function () {
        $('#txtCrearTitulo').focus();
    });

    $('#editarEventoModal').on('shown.bs.modal', function () {
        $('#txtEditarTitulo').focus();
    });

    $('#duplicarEventoModal').on('shown.bs.modal', function () {
        $('#txtDuplicarTitulo').focus();
    });

}
