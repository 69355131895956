if ($('#app-staff').length > 0) {

    var vmStaff = new Vue({
        el: '#app-staff',

        data: {
            area: '',
            docentes: []
        },

        created: function () {
            this.area = area;
            this.docentes = docentes;
        }
    });

}
