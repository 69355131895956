if ($('#plataforma-website-nosotros').length > 0) {

    var vmPlataformaWebsiteNosotros = new Vue({
        el: '#plataforma-website-nosotros',

        data: {
            nosotros: {
                descripcion: '', vision: '', mision: '', historia: ''
            },
        },

        created: function () {
            if (!isEmpty(nosotros)) rellenarObjeto(this.nosotros, nosotros);
        },

        mounted: function () {
            CKEDITOR.replace('txtEditarHistoria');
            CKEDITOR.instances['txtEditarHistoria'].setData(this.nosotros.historia);
            $('#txtEditarDescripcion').focus();
        },

        methods: {
            guardarNosotros: function () {
                var self = this;

                var formData = new FormData();
                formData.append('_method', 'PUT');
                for (var propiedad in self.nosotros) {
                    formData.append(propiedad, self.nosotros[propiedad]);
                }
                formData.append('historia', CKEDITOR.instances['txtEditarHistoria'].getData());
                formData.append('imagen_vm', $('#txtEditarImagenVM')[0].files[0]);
                formData.append('imagen_h', $('#txtEditarImagenHistoria')[0].files[0]);

                ajax_archivo('/plataforma/website/nosotros/actualizar', 'html', formData, $('#btnEditarNosotros'), function () {
                    $('#imgVM').attr('src', '/img/escuela/nosotros/vision-mision.jpg?random=' + new Date().getTime());
                    $('#imgHistoria').attr('src', '/img/escuela/nosotros/historia.jpg?random=' + new Date().getTime());
                    $('#txtEditarImagenVM').val('');
                    $('#txtEditarImagenHistoria').val('');
                    toastr.info('La información fue actualizada satisfactoriamente.', 'QUIÉNES SOMOS');
                });
            },
        }
    });

}
