if ($('#plataforma-website-documentos').length > 0) {

    var vmPlataformaWebsiteDocumentos = new Vue({
        el: '#plataforma-website-documentos',

        data: {
            documentos: [],
            tipos: ['Contenido', 'PDF'],
            crear: {
                titulo: '', tipo: ''
            },
            editar: {
                id: '', titulo: '', tipo: '', contenido: '', archivo: ''
            },
            temporal: {},
            eliminar: {
                _method: 'DELETE', id: ''
            },
        },

        created: function () {
            this.documentos = documentos;
        },

        computed: {
            documentosOrdenados: function () {
                return this.documentos.sort(function (a, b) {
                    return parseInt(a.numero) - parseInt(b.numero);
                });
            },
        },

        methods: {
            crearDocumento: function () {
                var self = this;

                var formData = new FormData();
                for (var propiedad in self.crear) {
                    formData.append(propiedad, self.crear[propiedad]);
                }
                if (parseInt(self.crear.tipo) === 1) {
                    formData.append('contenido', CKEDITOR.instances['txtCrearContenido'].getData());
                } else {
                    formData.append('archivo', $('#txtCrearArchivo')[0].files[0]);
                }

                ajax_archivo('/plataforma/website/documentos/almacenar', 'json', formData, $('#btnCrearDocumento'), function (documento) {
                    self.documentos.push(documento);
                    limpiarObjeto(self.crear);
                    $('#crearDocumentoModal').modal('hide');
                    CKEDITOR.instances['txtCrearContenido'].setData('');
                    $('#txtCrearArchivo').val('');
                    toastr.success('El documento "' + documento.titulo + '" fue creado satisfactoriamente.', 'DOCUMENTOS');
                });
            },

            editarDocumento: function (documento) {
                rellenarObjeto(this.editar, documento);
                this.temporal = documento;
                this.$refs.editarDocumentoTitulo.textContent = documento.titulo;
                CKEDITOR.instances['txtEditarContenido'].setData(this.editar.contenido);
                $('#txtEditarArchivo').val('');
                $('#editarDocumentoModal').modal('show');
            },

            guardarDocumento: function () {
                var self = this;

                var formData = new FormData();
                formData.append('_method', 'PUT');
                for (var propiedad in self.editar) {
                    formData.append(propiedad, self.editar[propiedad]);
                }
                if (parseInt(self.editar.tipo) === 1) {
                    formData.append('contenido', CKEDITOR.instances['txtEditarContenido'].getData());
                } else {
                    formData.append('archivo', $('#txtEditarArchivo')[0].files[0]);
                }

                ajax_archivo('/plataforma/website/documentos/actualizar', 'json', formData, $('#btnEditarDocumento'), function (documento) {
                    rellenarObjeto(self.temporal, documento);
                    $('#editarDocumentoModal').modal('hide');
                    toastr.info('El documento "' + documento.titulo + '" fue actualizado satisfactoriamente.', 'DOCUMENTOS');
                });
            },

            eliminarDocumento: function (event) {
                var self = this;
                if (confirm('¿Seguro(a) qué desea eliminar el documento "' + self.temporal.titulo + '"?')) {
                    self.eliminar.id = self.temporal.id;
                    ajax('/plataforma/website/documentos/eliminar', 'json', self.eliminar, $(event.currentTarget), function (documentos) {
                        self.documentos = documentos;
                        $('#editarDocumentoModal').modal('hide');
                        toastr.warning('El documento "' + self.temporal.titulo + '" ha sido eliminado.', 'DOCUMENTOS');
                    });
                }
            },
        }
    });

    $('#tblDocumentos tbody').sortable({
        helper: fixHelperModified,
        stop: function(event, ui) {
            ui.item.addClass('warning');
            var datos = { _method: 'PUT', lista: [] };
            $("#tblDocumentos tbody tr").each(function () {
                datos.lista.push($(this).data('documento-id'));
            });
            ajax('/plataforma/website/documentos/ordenar', 'json', datos, null, function(documentos) {
                ui.item.removeClass('warning');
                vmPlataformaWebsiteDocumentos.documentos = documentos;
                toastr.info('El orden de los documentos fue actualizado satisfactoriamente.', 'DOCUMENTOS');
            });
        }
    }).disableSelection();

    CKEDITOR.replace('txtCrearContenido');
    $('#crearDocumentoModal').on('shown.bs.modal', function () {
        $('#txtCrearTitulo').focus();
    });

    CKEDITOR.replace('txtEditarContenido');
    $('#editarDocumentoModal').on('shown.bs.modal', function () {
        $('#txtEditarTitulo').focus();
    });

}
