if ($('#plataforma-configuracion-usuarios').length > 0) {

    var vmPlataformaConfiguracionUsuarios = new Vue({
        el: '#plataforma-configuracion-usuarios',

        data: {
            usuarios: [],
            tipos: ['Indefinido', 'Administrador', 'Secretaria', 'Supervisor', 'Docente', 'Asistente'],
            busqueda: {
                estado: false, texto: ''
            },
            crear: {
                nombre: '', identidad: '', tipo: '', email: '', fecha_nacimiento: '', tel_celular: '', tel_casa: '', estado: 1, web: 1, password: ''
            },
            editar: {
                _method: 'PUT', id: '', nombre: '', identidad: '', tipo: '', email: '', fecha_nacimiento: '', tel_celular: '', tel_casa: '', estado: 1, web: 1, password: ''
            },
            temporal: {},
            eliminar: {
                _method: 'DELETE', id: '',
            },
        },

        created: function () {
            this.usuarios = usuarios;
        },

        computed: {
            usuariosOrdenados: function () {
                return this.usuarios.sort(function (a, b) {
                    return a.nombre.localeCompare(b.nombre);
                });
            },
        },

        methods: {
            crearUsuario: function () {
                var self = this;

                var formData = new FormData();
                for (var propiedad in self.crear) {
                    formData.append(propiedad, self.crear[propiedad]);
                }
                formData.append('foto', $('#txtCrearFoto')[0].files[0]);

                ajax_archivo('/plataforma/configuracion/usuarios/almacenar', 'json', formData, $('#btnCrearUsuario'), function (usuario) {
                    self.usuarios.push(usuario);
                    limpiarObjeto(self.crear);
                    self.crear.estado = 1;
                    self.crear.web = 1;
                    $('#crearUsuarioModal').modal('hide');
                    $('#txtCrearFoto').val('');
                    toastr.success('El usuario "' + usuario.nombre + '" fue creado satisfactoriamente.', 'USUARIOS');
                });
            },

            actualizarEstado: function (usuario) {
                usuario.estado = parseInt(usuario.estado) === 1 ? 0 : 1;
                var datos = {
                    _method: 'PUT', id: usuario.id, estado: usuario.estado
                };
                ajax('/plataforma/configuracion/usuarios/actualizar_estado', 'html', datos, null, function () {
                    toastr.info('El estado del usuario "' + usuario.nombre + '" fue actualizado satisfactoriamente.', 'USUARIOS');
                });
            },

            editarUsuario: function (usuario) {
                rellenarObjeto(this.editar, usuario, ['_method']);
                this.temporal = usuario;
                this.$refs.editarUsuarioTitulo.textContent = usuario.nombre;
                $('#editarUsuarioModal').modal('show');
            },

            guardarUsuario: function () {
                var self = this;

                var formData = new FormData();
                for (var propiedad in self.editar) {
                    formData.append(propiedad, self.editar[propiedad]);
                }
                formData.append('foto', $('#txtEditarFoto')[0].files[0]);

                ajax_archivo('/plataforma/configuracion/usuarios/actualizar', 'json', formData, $('#btnEditarUsuario'), function (usuario) {
                    rellenarObjeto(self.temporal, usuario);
                    $('#editarUsuarioModal').modal('hide');
                    $('#txtEditarFoto').val('');
                    $('#img-' + usuario.id).attr('src', '/img/usuarios/' + usuario.id + '.jpg?random=' + new Date().getTime());
                    toastr.info('El usuario "' + usuario.nombre + '" fue actualizado satisfactoriamente.', 'USUARIOS');
                });
            },

            eliminarUsuario: function (event) {
                var self = this;
                if (confirm('¿Seguro(a) qué desea eliminar el usuario "' + self.temporal.nombre + '"?')) {
                    self.eliminar.id = self.temporal.id;
                    ajax('/plataforma/configuracion/usuarios/eliminar', 'html', self.eliminar, $(event.currentTarget), function () {
                        toastr.warning('El usuario "' + self.temporal.nombre + '" ha sido eliminado.', 'USUARIOS');
                        removerObjeto(self.usuarios, self.temporal);
                        $('#editarUsuarioModal').modal('hide');
                    });
                }
            },

            realizarBusqueda: function () {
                var self = this;
                ajax('/plataforma/configuracion/usuarios/busqueda', 'json', self.busqueda, $('#btnBusqueda'), function (usuarios) {
                    self.$refs.busquedaTitulo.textContent = self.busqueda.texto;
                    self.busqueda.estado = true;
                    self.usuarios = usuarios;
                    toastr.info('Se obtuvieron un total de ' + usuarios.length + ' resultado(s).', 'USUARIOS');
                });
            },

            removerBusqueda: function (event) {
                var self = this;
                var evento = event.currentTarget;
                evento.textContent = 'Cargando usuarios...';
                ajax('/plataforma/configuracion/usuarios/obtener', 'json', { dato: Math.random() }, null, function (usuarios) {
                    evento.textContent = 'Remover Búsqueda';
                    self.usuarios = usuarios;
                    self.busqueda.estado = false;
                });
            }
        }
    });

    $('#crearUsuarioModal').on('shown.bs.modal', function () {
        $('#txtCrearNombre').focus();
    });

    $('#editarUsuarioModal').on('shown.bs.modal', function () {
        $('#txtEditarNombre').focus();
    });

}
