if ($('#app-instalaciones').length > 0) {

    var vmInstalaciones = new Vue({
        el: '#app-instalaciones',

        data: {
            area: '',
            instalaciones: []
        },

        created: function () {
            this.area = area;
            this.instalaciones = instalaciones;
        }
    });

}
