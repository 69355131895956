if ($('#plataforma-website-reglamentos').length > 0) {

    var vmPlataformaWebsiteReglamentos = new Vue({
        el: '#plataforma-website-reglamentos',

        data: {
            reglamentos: [],
            tipos: ['Contenido', 'PDF'],
            crear: {
                titulo: '', tipo: ''
            },
            editar: {
                id: '', titulo: '', tipo: '', contenido: '', archivo: ''
            },
            temporal: {},
            eliminar: {
                _method: 'DELETE', id: ''
            },
        },

        created: function () {
            this.reglamentos = reglamentos;
        },

        computed: {
            reglamentosOrdenados: function () {
                return this.reglamentos.sort(function (a, b) {
                    return parseInt(a.numero) - parseInt(b.numero);
                });
            },
        },

        methods: {
            crearReglamento: function () {
                var self = this;

                var formData = new FormData();
                for (var propiedad in self.crear) {
                    formData.append(propiedad, self.crear[propiedad]);
                }
                if (parseInt(self.crear.tipo) === 1) {
                    formData.append('contenido', CKEDITOR.instances['txtCrearContenido'].getData());
                } else {
                    formData.append('archivo', $('#txtCrearArchivo')[0].files[0]);
                }

                ajax_archivo('/plataforma/website/reglamentos/almacenar', 'json', formData, $('#btnCrearReglamento'), function (reglamento) {
                    self.reglamentos.push(reglamento);
                    limpiarObjeto(self.crear);
                    $('#crearReglamentoModal').modal('hide');
                    CKEDITOR.instances['txtCrearContenido'].setData('');
                    $('#txtCrearArchivo').val('');
                    toastr.success('El reglamento "' + reglamento.titulo + '" fue creado satisfactoriamente.', 'REGLAMENTOS');
                });
            },

            editarReglamento: function (reglamento) {
                rellenarObjeto(this.editar, reglamento);
                this.temporal = reglamento;
                this.$refs.editarReglamentoTitulo.textContent = reglamento.titulo;
                CKEDITOR.instances['txtEditarContenido'].setData(this.editar.contenido);
                $('#txtEditarArchivo').val('');
                $('#editarReglamentoModal').modal('show');
            },

            guardarReglamento: function () {
                var self = this;

                var formData = new FormData();
                formData.append('_method', 'PUT');
                for (var propiedad in self.editar) {
                    formData.append(propiedad, self.editar[propiedad]);
                }
                if (parseInt(self.editar.tipo) === 1) {
                    formData.append('contenido', CKEDITOR.instances['txtEditarContenido'].getData());
                } else {
                    formData.append('archivo', $('#txtEditarArchivo')[0].files[0]);
                }

                ajax_archivo('/plataforma/website/reglamentos/actualizar', 'json', formData, $('#btnEditarReglamento'), function (reglamento) {
                    rellenarObjeto(self.temporal, reglamento);
                    $('#editarReglamentoModal').modal('hide');
                    toastr.info('El reglamento "' + reglamento.titulo + '" fue actualizado satisfactoriamente.', 'REGLAMENTOS');
                });
            },

            eliminarReglamento: function (event) {
                var self = this;
                if (confirm('¿Seguro(a) qué desea eliminar el reglamento "' + self.temporal.titulo + '"?')) {
                    self.eliminar.id = self.temporal.id;
                    ajax('/plataforma/website/reglamentos/eliminar', 'json', self.eliminar, $(event.currentTarget), function (reglamentos) {
                        self.reglamentos = reglamentos;
                        $('#editarReglamentoModal').modal('hide');
                        toastr.warning('El reglamento "' + self.temporal.titulo + '" ha sido eliminado.', 'REGLAMENTOS');
                    });
                }
            },
        }
    });

    $('#tblReglamentos tbody').sortable({
        helper: fixHelperModified,
        stop: function(event, ui) {
            ui.item.addClass('warning');
            var datos = { _method: 'PUT', lista: [] };
            $("#tblReglamentos tbody tr").each(function () {
                datos.lista.push($(this).data('reglamento-id'));
            });
            ajax('/plataforma/website/reglamentos/ordenar', 'json', datos, null, function(reglamentos) {
                ui.item.removeClass('warning');
                vmPlataformaWebsiteReglamentos.reglamentos = reglamentos;
                toastr.info('El orden de los reglamentos fue actualizado satisfactoriamente.', 'REGLAMENTOS');
            });
        }
    }).disableSelection();

    CKEDITOR.replace('txtCrearContenido');
    $('#crearReglamentoModal').on('shown.bs.modal', function () {
        $('#txtCrearTitulo').focus();
    });

    CKEDITOR.replace('txtEditarContenido');
    $('#editarReglamentoModal').on('shown.bs.modal', function () {
        $('#txtEditarTitulo').focus();
    });

}
