Vue.filter('fecha_hora', function (value) {
    return moment(value).format('DD/MM/YYYY hh:mm a');
});

Vue.filter('fecha', function (value) {
    return moment(value).format('DD/MM/YYYY');
});

Vue.filter('dia', function (value) {
    return moment(value).format('DD');
});

Vue.filter('mes', function (value) {
    return moment(value).format('MMM');
});

Vue.filter('ano', function (value) {
    return moment(value).format('YYYY');
});

Vue.filter('hora', function (value) {
    return moment(value).format('hh:mm a');
});

Vue.filter('edad', function (value) {
    return moment().diff(value, 'years');
});
