if ($('#frm-contacto').length > 0) {

    var vmContacto = new Vue({
        el: '#frm-contacto',

        data: {
            contacto: {
                nombre: '',
                email: '',
                mensaje: ''
            }
        },

        methods: {
            enviarMensaje: function () {
                var self = this;
                ajax('/contacto', 'html', self.contacto, $('#btnEnviarMensajeContacto'), function () {
                    toastr.success('El mensaje ha sido enviado satisfactoriamente.', 'CONTACTO');
                    limpiarObjeto(self.contacto);
                    self.$nextTick(function () {
                        $('#txtWwwContactoNombre').focus();
                    });
                });
            }
        }
    });

}
