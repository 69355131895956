if ($('#plataforma-website-areas').length > 0) {

    var vmPlataformaWebsiteAreas = new Vue({
        el: '#plataforma-website-areas',

        data: {
            areas: [],
            subida: {
                estado: 0, progreso: 0
            },
        },

        created: function () {
            this.areas = areas;
        },

        mounted: function () {
            this.areas.forEach(function (area) {
                CKEDITOR.replace('txtDescripcion-' + area.id);
                CKEDITOR.instances['txtDescripcion-' + area.id].setData(area.descripcion);
            });
        },

        computed: {
            areasOrdenadas: function () {
                return this.areas.sort(function (a, b) {
                    return parseInt(a.numero) - parseInt(b.numero);
                });
            },
        },

        methods: {
            guardarDescripcion: function (area) {
                var self = this;
                var datos = { _method: 'PUT', id: area.id, descripcion: CKEDITOR.instances['txtDescripcion-' + area.id].getData() };
                ajax('/plataforma/website/areas/actualizar', 'html', datos, $('#btnDescripcion-' + area.id), function () {
                    toastr.info('La descripción del área "' + area.nombre + '" fue actualizada satisfactoriamente.', 'ÁREAS');
                });
            },

            // INSTALACIONES
            agregarInstalacion: function (area) {
                var self = this;
                if (parseInt(self.subida.estado) > 0) {
                    alert('Actualmente existe una subida en proceso.');
                } else {
                    if (document.getElementById('txtAgregarInstalacionImagen-' + area.id).files.length > 20) {
                        alert('Solo se permite subir un máximo de 20 fotos por solicitud.');
                    } else {
                        var formData = new FormData();
                        formData.append('area_id', area.id);
                        for (var i = 0; i < document.getElementById('txtAgregarInstalacionImagen-' + area.id).files.length; i++) {
                            formData.append('imagenes[]', document.getElementById('txtAgregarInstalacionImagen-' + area.id).files[i]);
                        }
                        self.subida.estado = area.id;
                        self.subida.progreso = 0;
                        self.$nextTick(function () {
                            ajaxArchivoProgreso('/plataforma/website/areas/instalaciones/almacenar', 'json', formData, $('#btnAgregarInstalacionImagen-' + area.id), self, function (instalaciones) {
                                instalaciones.forEach(function (instalacion) {
                                    area.instalaciones.push(instalacion);
                                });
                                $('#txtAgregarInstalacionImagen-' + area.id).val('');
                                $('#txtAgregarInstalacionImagen-' + area.id).focus();
                                self.subida.estado = 0;
                                toastr.success('Las imágenes fueron subidas satisfactoriamente.', 'GALERÍAS');
                            });
                        });
                    }
                }
            },

            eliminarInstalacion: function (event, area, instalacion) {
                if (confirm('¿Seguro(a) qué desea eliminar la imagen seleccionada?')) {
                    var datos = { _method: 'DELETE', id: instalacion.id };
                    ajax('/plataforma/website/areas/instalaciones/eliminar', 'html', datos, $(event.currentTarget), function () {
                        toastr.warning('La imagen ha sido eliminada.', 'ÁREAS');
                        removerObjeto(area.instalaciones, instalacion);
                    });
                }
            },

            cambiarTituloImagen: function (instalacion) {
                var respuesta = prompt('Nuevo título:', instalacion.titulo);
                if (respuesta != null) {
                    var datos = { _method: 'PUT', id: instalacion.id, titulo: respuesta };
                    ajax('/plataforma/website/areas/instalaciones/titulo', 'html', datos, null, function () {
                        toastr.info('El texto del título fue actualizado satisfactoriamente.', 'ÁREAS');
                        instalacion.titulo = respuesta;
                    });
                }
            },
        }
    });

    /*CKEDITOR.replace('txtCrearDetalle');
    $('#crearareaModal').on('shown.bs.modal', function () {
        $('#txtCrearTitulo').focus();
    });

    CKEDITOR.replace('txtEditarDetalle');
    $('#editarareaModal').on('shown.bs.modal', function () {
        $('#txtEditarTitulo').focus();
    });*/

}
