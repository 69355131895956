if ($('#plataforma-website-uniformes-principal').length > 0) {

    var preciosModal = false;

    var vmPlataformaWebsiteUniformesPrincipal = new Vue({
        el: '#plataforma-website-uniformes-principal',

        data: {
            uniformes: [],
            precios_uniformes: 0,
            niveles: ['', 'General', 'Señoritas', 'Caballeros'],
            crear: {
                titulo: '', nivel: ''
            },
            editar: {
                id: '', titulo: '', nivel: '', imagen: ''
            },
            temporal: {},
            eliminar: {
                _method: 'DELETE', id: ''
            },
        },

        created: function () {
            this.uniformes = uniformes;
            this.precios_uniformes = estados.precios_uniformes;
        },

        computed: {
            uniformesOrdenados: function () {
                return this.uniformes.sort(function (a, b) {
                    return parseInt(a.nivel) - parseInt(b.nivel);
                });
            },
        },

        methods: {
            cambiarEstadoMostrarPrecios: function () {
                var self = this;
                var datos = { precios_uniformes: self.precios_uniformes };
                ajax('/plataforma/website/uniformes/actualizar_estado_precios', 'html', datos, null, function () {
                    toastr.info('El estado de los precios en uniformes ha sido actualizado.', 'UNIFORMES');
                });
            },

            crearUniforme: function () {
                var self = this;

                var formData = new FormData();
                for (var propiedad in self.crear) {
                    formData.append(propiedad, self.crear[propiedad]);
                }
                formData.append('imagen', $('#txtCrearUniformeImagen')[0].files[0]);

                ajax_archivo('/plataforma/website/uniformes/almacenar', 'json', formData, $('#btnCrearUniforme'), function (uniforme) {
                    self.uniformes.push(uniforme);
                    limpiarObjeto(self.crear);
                    $('#txtCrearUniformeImagen').val('');
                    preciosModal = true;
                    self.mostrarPrecios(uniforme);
                    $('#crearUniformeModal').modal('hide');
                    toastr.success('El uniforme "' + uniforme.titulo + '" fue creado satisfactoriamente.', 'UNIFORMES');
                });
            },

            editarUniforme: function (uniforme) {
                rellenarObjeto(this.editar, uniforme);
                this.temporal = uniforme;
                this.$refs.editarUniformeTitulo.textContent = uniforme.titulo;
                $('#txtEditarImagen').val('');
                $('#editarUniformeModal').modal('show');
            },

            guardarUniforme: function () {
                var self = this;

                var formData = new FormData();
                formData.append('_method', 'PUT');
                for (var propiedad in self.editar) {
                    formData.append(propiedad, self.editar[propiedad]);
                }
                formData.append('imagen', $('#txtEditarUniformeImagen')[0].files[0]);

                ajax_archivo('/plataforma/website/uniformes/actualizar', 'json', formData, $('#btnEditarUniforme'), function (uniforme) {
                    rellenarObjeto(self.temporal, uniforme);
                    $('#editarUniformeModal').modal('hide');
                    toastr.info('El uniforme "' + uniforme.titulo + '" fue actualizado satisfactoriamente.', 'UNIFORMES');
                });
            },

            eliminarUniforme: function (event) {
                var self = this;
                if (confirm('¿Seguro(a) qué desea eliminar el Uniforme "' + self.temporal.titulo + '"?')) {
                    self.eliminar.id = self.temporal.id;
                    ajax('/plataforma/website/uniformes/eliminar', 'html', self.eliminar, $(event.currentTarget), function () {
                        toastr.warning('El uniforme "' + self.temporal.titulo + '" ha sido eliminado.', 'UNIFORMES');
                        removerObjeto(self.uniformes, self.temporal);
                        $('#editarUniformeModal').modal('hide');
                    });
                }
            },

            mostrarPrecios: function(uniforme) {
                vmPlataformaWebsiteUniformesPrecios.precios = uniforme.precios;
                vmPlataformaWebsiteUniformesPrecios.crear.uniforme_id = uniforme.id;
                vmPlataformaWebsiteUniformesPrecios.modo = '';
                vmPlataformaWebsiteUniformesPrecios.$refs.tituloPrecios.textContent = uniforme.titulo;
                $('#preciosUniformeModal').modal('show')
            },
        }
    });

    $('#crearUniformeModal').on('shown.bs.modal', function () {
        preciosModal = false;
        $('#txtCrearUniformeTitulo').focus();
    });

    $('#crearUniformeModal').on('hidden.bs.modal', function () {
        if (preciosModal) $('#preciosUniformeModal').modal('show');
    });

    $('#editarUniformeModal').on('shown.bs.modal', function () {
        $('#txtEditarUniformeTitulo').focus();
    });

}
