if ($('#plataforma-website-galerias').length > 0) {

    var vmPlataformaWebsiteGalerias = new Vue({
        el: '#plataforma-website-galerias',

        data: {
            galerias: [],
            crear: {
                titulo: '', descripcion: '', fecha: ''
            },
            editar: {
                _method: 'PUT', id: '', titulo: '', descripcion: '', fecha: '', estado: ''
            },
            subida: {
                estado: false, tamano: 0, progreso: 0
            },
            temporal: {},
            eliminar: {
                _method: 'DELETE', id: ''
            },
            fotos: {
                galeria_id: '', lista: []
            },
        },

        created: function () {
            this.galerias = galerias;
        },

        computed: {
            galeriasOrdenadas: function () {
                return this.galerias.sort(function (a, b) {
                    return new Date(b.fecha) - new Date(a.fecha);
                });
            },

            fotosOrdenadas: function () {
                return this.fotos.lista.sort(function (a, b) {
                    return parseInt(a.id) - parseInt(b.id);
                });
            },
        },

        methods: {
            crearGaleria: function (event) {
                var self = this;

                if (document.getElementById('txtCrearFotos').files.length > 20) {
                    alert('Solo se permite subir un máximo de 20 fotos por solicitud.');
                } else {
                    var formData = new FormData();
                    for (var propiedad in self.crear) {
                        formData.append(propiedad, self.crear[propiedad]);
                    }
                    for (var i = 0; i < document.getElementById('txtCrearFotos').files.length; i++) {
                        formData.append('fotos[]', document.getElementById('txtCrearFotos').files[i]);
                    }

                    self.subida.estado = true
                    self.subida.progreso = 0;
                    self.$nextTick(function () {
                        ajaxArchivoProgreso('/plataforma/website/galerias/almacenar', 'json', formData, $('#btnCrearGaleria'), self, function (galeria) {
                            self.galerias.push(galeria);
                            $('#crearGaleriaModal').modal('hide');
                            limpiarObjeto(self.crear);
                            $('#txtCrearFotos').val('');
                            self.subida.tamano = 0;
                            self.subida.estado = false;
                            self.subida.progreso = 0;
                            toastr.success('La galería "' + galeria.titulo + '" fue creada satisfactoriamente.', 'GALERÍAS');
                        });
                    });
                }

            },

            editarGaleria: function (galeria) {
                rellenarObjeto(this.editar, galeria, ['_method']);
                this.temporal = galeria;
                this.$refs.editarGaleriaTitulo.textContent = galeria.titulo;
                $('#editarGaleriaModal').modal('show');
            },

            guardarGaleria: function () {
                var self = this;
                ajax('/plataforma/website/galerias/actualizar', 'json', self.editar, $('#btnEditarGaleria'), function (galeria) {
                    rellenarObjeto(self.temporal, galeria, ['fotos']);
                    $('#editarGaleriaModal').modal('hide');
                    toastr.info('La galería "' + galeria.titulo + '" fue actualizada satisfactoriamente.', 'GALERÍAS');
                });
            },

            eliminarGaleria: function (event) {
                var self = this;
                if (confirm('¿Seguro(a) qué desea eliminar la galería "' + self.temporal.titulo + '"?')) {
                    self.eliminar.id = self.temporal.id;
                    ajax('/plataforma/website/galerias/eliminar', 'html', self.eliminar, $(event.currentTarget), function () {
                        toastr.warning('La galería "' + self.temporal.titulo + '" ha sido eliminada.', 'GALERÍAS');
                        removerObjeto(self.galerias, self.temporal);
                        $('#editarGaleriaModal').modal('hide');
                    });
                }
            },

            // FOTOS
            editarFotos: function (galeria) {
                $('#txtSubirFotos').val('');
                this.subida.tamano = 0;
                this.subida.progreso = 0;
                this.subida.estado = false;
                this.fotos.lista = galeria.fotos;
                this.fotos.galeria_id = galeria.id;
                this.$refs.fotosGaleriaTitulo.textContent = galeria.titulo;
                $('#fotosGaleriaModal').modal('show');
            },

            subirFotos: function () {
                var self = this;

                if (document.getElementById('txtSubirFotos').files.length > 20) {
                    alert('solo se permite subir un máximo de 20 fotos por solicitud.');
                } else {
                    var formData = new FormData();
                    formData.append('galeria_id', self.fotos.galeria_id);
                    for (var i = 0; i < document.getElementById('txtSubirFotos').files.length; i++) {
                        formData.append('fotos[]', document.getElementById('txtSubirFotos').files[i]);
                    }

                    self.subida.estado = true;
                    self.subida.progreso = 0;
                    self.$nextTick(function () {
                        ajaxArchivoProgreso('/plataforma/website/galerias/fotos/almacenar', 'json', formData, $('#btnSubirFotos'), self, function (fotos) {
                            fotos.forEach(function (foto) {
                                self.fotos.lista.push(foto);
                            });
                            $('#txtSubirFotos').val('');
                            $('#txtSubirFotos').focus();
                            self.subida.tamano = 0;
                            self.subida.estado = false;
                            toastr.success('Las fotos fueron subidas satisfactoriamente.', 'GALERÍAS');
                        });
                    });
                }
            },

            cambiarTituloFoto: function (foto) {
                var respuesta = prompt('Nuevo título:', foto.titulo);
                if (respuesta != null) {
                    var datos = { _method: 'PUT', id: foto.id, titulo: respuesta };
                    ajax('/plataforma/website/galerias/fotos/titulo', 'html', datos, null, function () {
                        toastr.info('El texto del título fue actualizado satisfactoriamente.', 'GALERÍAS');
                        foto.titulo = respuesta;
                    });
                }
            },

            eliminarFoto: function (event, foto) {
                var self = this;
                if (confirm('¿Seguro(a) qué desea eliminar la foto seleccionada "?')) {
                    var datos = { _method: 'DELETE', id: foto.id };
                    ajax('/plataforma/website/galerias/fotos/eliminar', 'html', datos, $(event.currentTarget), function () {
                        toastr.warning('La foto ha sido eliminada.', 'GALERÍAS');
                        removerObjeto(self.fotos.lista, foto);
                    });
                }
            },

            actualizarTamano: function (input, elemento) {
                var tamano = this.tamanoDeArchivos($('#' + input).get(0).files);
                this.subida.tamano = tamano;
            },

            tamanoDeArchivos: function (archivos) {
                var total = 0;
                for (i = 0; i < archivos.length; i++) {
                    total += archivos[i].size;
                }
                return Math.round(((total / 1024) / 1024) * 100) / 100;
            },
        }
    });

    $('#crearGaleriaModal').on('show.bs.modal', function () {
        vmPlataformaWebsiteGalerias.subida.estado = false;
        vmPlataformaWebsiteGalerias.subida.progreso = 0;
        vmPlataformaWebsiteGalerias.subida.tamano = 0;
        $('#txtCrearFotos').val('');
    });

    $('#crearGaleriaModal').on('shown.bs.modal', function () {
        $('#txtCrearTitulo').focus();
    });

    $('#editarGaleriaModal').on('shown.bs.modal', function () {
        $('#txtEditarTitulo').focus();
    });

}
