//Vue.config.debug = true;

$(function () {
    $('[data-toggle="tooltip"]').tooltip();
});

// AJAX
    $.ajaxSetup({
        cache: false,
        headers: {
            'X-CSRF-TOKEN': $('meta[name=csrf-token]').attr("content")
        }
    });

    function ajax(direccion, tipo, datos, btn, callback)
    {
        if (btn !== null) {
            if (btn.length > 0) {
                btn.data('loading-text', '<img src="/img/loaders/' + btn.data('tipo') + '.gif"> ' + btn.text());
                btn.attr('autocomplete', 'off');
                btn.button('loading');
            }
        }
        $.ajax({
            url: direccion,
            type: 'post',
            dataType: tipo,
            data: datos,
            complete: function() {
                if (btn !== null) {
                    if (btn.length > 0) {
                        btn.button('reset');
                    }
                }
            },
            success: callback,
            error: function(xhr, ajaxOption, thrownError) {
                try {
                    var errors = JSON.parse(xhr.responseText);
                    var mensaje = 'Errores:\n\n';
                    $.each(errors, function(key, value) {
                        mensaje += (value + '\n');
                    });
                    alert(mensaje);
                }
                catch (err) {
                    console.log(thrownError);
                    console.log(xhr.responseText);
                    var myWindow = window.open('', 'MsgWindow');
                    myWindow.document.write(xhr.responseText);
                }
            }
        });
    }

    function ajax_archivo(url, type, data, btn, callback)
    {
        if (btn !== null) {
            if (btn.length > 0) {
                btn.data('loading-text', '<img src="/img/loaders/' + btn.data('tipo') + '.gif"> ' + btn.text());
                btn.attr('autocomplete', 'off');
                btn.button('loading');
            }
        }
        $.ajax({
            url: url,
            type: 'post',
            dataType: type,
            processData: false,
            contentType: false,
            data: data,
            complete: function() {
                if (btn !== null) {
                    if (btn.length > 0) {
                        btn.button('reset');
                    }
                }
            },
            error: function(xhr, ajaxOption, thrownError) {
                try {
                    var errors = JSON.parse(xhr.responseText);
                    var mensaje = 'Errores:\n\n';
                    $.each(errors, function(key, value) {
                        mensaje += (value + '\n');
                    });
                    alert(mensaje);
                }
                catch (err) {
                    console.log(thrownError);
                    console.log(xhr.responseText);
                    var myWindow = window.open('', 'MsgWindow');
                    myWindow.document.write(xhr.responseText);
                }
            },
            success: callback,
        });
    }

    function ajaxArchivoProgreso(url, type, data, btn, vueObject, callback)
    {
        if (btn !== null) {
            if (btn.length > 0) {
                btn.data('loading-text', '<img src="/img/loaders/' + btn.data('tipo') + '.gif"> ' + btn.text());
                btn.attr('autocomplete', 'off');
                btn.button('loading');
            }
        }
        $.ajax({
            url: url,
            type: 'post',
            dataType: type,
            processData: false,
            contentType: false,
            data: data,
            xhr: function() {
                var xhr = new window.XMLHttpRequest();
                xhr.upload.addEventListener("progress", function(evt) {
                    if (evt.lengthComputable) {
                        var percentComplete = evt.loaded / evt.total;
                        percentComplete = parseInt(percentComplete * 100);
                        if (vueObject !== null) {
                            vueObject.subida.progreso = percentComplete;
                        }
                        if (percentComplete === 100) {
                        }
                    }
                }, false);
                return xhr;
            },
            complete: function() {
                if (btn !== null) {
                    if (btn.length > 0) {
                        btn.button('reset');
                    }
                }
            },
            error: function(xhr, ajaxOption, thrownError) {
                try {
                    var errors = JSON.parse(xhr.responseText);
                    var mensaje = 'Errores:\n\n';
                    $.each(errors, function(key, value) {
                        mensaje += (value + '\n');
                    });
                    alert(mensaje);
                }
                catch (err) {
                    console.log(thrownError);
                    console.log(xhr.responseText);
                    var myWindow = window.open('', 'MsgWindow');
                    myWindow.document.write(xhr.responseText);
                }
            },
            success: callback,
        });
    }

// TOASTR
    toastr.options = {
        "closeButton": false,
        "debug": false,
        "newestOnTop": false,
        "progressBar": false,
        "positionClass": "toast-bottom-left",
        "preventDuplicates": false,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    }

// HELPER FUNCTION TO KEEP TABLE ROW FROM COLLAPSING WHEN BEING SORTED
    var fixHelperModified = function(e, tr) {
        var $originals = tr.children();
        var $helper = tr.clone();
        $helper.children().each(function(index) {
          $(this).width($originals.eq(index).width())
        });
        return $helper;
    };

// IF EMPTY OBJECT
    // Speed up calls to hasOwnProperty
    var hasOwnProperty = Object.prototype.hasOwnProperty;

    function isEmpty(obj) {
        // null and undefined are "empty"
        if (obj == null) return true;

        // Assume if it has a length property with a non-zero value
        // that that property is correct.
        if (obj.length > 0)    return false;
        if (obj.length === 0)  return true;

        // Otherwise, does it have any properties of its own?
        // Note that this doesn't handle
        // toString and valueOf enumeration bugs in IE < 9
        for (var key in obj) {
            if (hasOwnProperty.call(obj, key)) return false;
        }

        return true;
    }

// LIMPIAR OBJETO
    function limpiarObjeto(objeto, exclusiones)
    {
        for (var propiedad in objeto) {
            if (typeof exclusiones == 'undefined') {
                objeto[propiedad] = '';
            } else {
                var resultado = false;
                exclusiones.forEach(function (exclusion) {
                    if (propiedad == exclusion) {
                        resultado = true;
                    }
                });
                if (!resultado) {
                    objeto[propiedad] = '';
                }
            }
        }
    }

// RELLENAR OBJETO
    function rellenarObjeto(objeto_a_rellenar, objeto_de_donde_rellenar, exclusiones)
    {
        for (var propiedad in objeto_a_rellenar) {
            if (typeof exclusiones == 'undefined') {
                objeto_a_rellenar[propiedad] = objeto_de_donde_rellenar[propiedad];
            } else {
                var resultado = false;
                exclusiones.forEach(function(exclusion) {
                    if (propiedad == exclusion) {
                        resultado = true;
                    }
                });
                if (!resultado) {
                    objeto_a_rellenar[propiedad] = objeto_de_donde_rellenar[propiedad];
                }
            }
        }
    }

// HIDE MODALS ON BROWSER BACK BUTTON
    $(document).off('show.bs.modal', 'div.modal').on('show.bs.modal', 'div.modal', function() {
        var modal = this;
        var hash = 'modal';
        window.location.hash = hash;
        window.onhashchange = function() {
            if (!location.hash){
                $(modal).modal('hide');
            }
        }
    });

    $(document).off('hide.bs.modal', 'div.modal').on('hide.bs.modal', 'div.modal', function() {
        var hash = 'modal';
        history.pushState('', document.title, window.location.pathname);
    });

// DISABLE LOADING BUTTON
    $('.btn-bloquear').on('click', function (e) {
        var btn = $(this);
        btn.data('loading-text', '<img src="/img/loaders/' + btn.data('tipo') + '.gif"> ' + btn.text()).attr('autocomplete', 'off');
        btn.button('loading');
    });

// FORMAT MONEY
    Number.prototype.formatMoney = function(c, d, t) {
    var n = this,
        c = isNaN(c = Math.abs(c)) ? 2 : c,
        d = d == undefined ? "." : d,
        t = t == undefined ? "," : t,
        s = n < 0 ? "-" : "",
        i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
        j = (j = i.length) > 3 ? j % 3 : 0;
       return s + 'L. ' + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "L1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
    };


// BLUEIMP GALLERY
    if ($('#gallery-links').length > 0) {
        document.getElementById('gallery-links').onclick = function (event) {
            event = event || window.event;
            var target = event.target || event.srcElement,
                link = target.src ? target.parentNode : target,
                options = {index: link, event: event},
                links = this.getElementsByTagName('a');
            blueimp.Gallery(links, options);
        };
    }

// REMVOVER ELEMENTO DE ARREGLO
    function removerObjeto(arreglo, elemento) {
        if (!arreglo.length) return;
        var indice = arreglo.indexOf(elemento);
        if (indice > -1) {
            arreglo.splice(indice, 1);
        }
    }
