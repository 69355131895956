if ($('#plataforma-website-uniformes-precios').length > 0) {

    var vmPlataformaWebsiteUniformesPrecios = new Vue({
        el: '#plataforma-website-uniformes-precios',

        data: {
            precios: [],
            modo: '',
            crear: {
                uniforme_id: '', talla: '', precio: ''
            },
            editar: {
                _method: 'PUT', id: '', talla: '', precio: ''
            },
            temporal: {},
            eliminar: {
                _method: 'DELETE', id: ''
            },
        },

        methods: {
            nuevoPrecio: function () {
                this.modo = 'Crear';
                this.$nextTick(function () {
                    $('#txtCrearPrecioTalla').focus();
                });
            },

            cancelarCreacion: function () {
                this.modo = '';
                limpiarObjeto(this.crear, ['uniforme_id']);
            },

            crearPrecio: function () {
                var self = this;
                ajax('/plataforma/website/uniformes/precios/almacenar', 'json', self.crear, $('#btnCrearPrecio'), function (precio) {
                    self.precios.push(precio);
                    toastr.success('El precio fue agregado satisfactoriamente.', 'UNIFORMES');
                    limpiarObjeto(self.crear, ['uniforme_id']);
                    self.modo = '';
                });
            },

            editarPrecio: function (precio) {
                rellenarObjeto(this.editar, precio, ['_method']);
                this.temporal = precio;
                this.modo = 'Editar';
                this.$refs.tituloEdicionPrecio.textContent = precio.talla;
                this.$nextTick(function () {
                    $('#txtEditarPrecioTalla').focus();
                });
            },

            guardarPrecio: function () {
                var self = this;
                ajax('/plataforma/website/uniformes/precios/actualizar', 'json', self.editar, $('#btnEditarPrecio'), function (precio) {
                    rellenarObjeto(self.temporal, precio);
                    toastr.info('El precio se actualizó satisfactoriamente.', 'UNIFORMES');
                    self.modo = '';
                });
            },

            eliminarPrecio: function (event, precio) {
                if (confirm('¿Seguro(a) qué desea eliminar el precio seleccionado?')) {
                    var self = this;
                    self.eliminar.id = precio.id;
                    ajax('/plataforma/website/uniformes/precios/eliminar', 'html', self.eliminar, $(event.currentTarget), function() {
                        toastr.warning('El precio ha sido eliminado.', 'UNIFORMES');
                        removerObjeto(self.precios, precio);
                    });
                }
            },
        }
    });

}
