if ($('#plataforma-website-banners').length > 0) {

    var vmPlataformaWebsiteBanners = new Vue({
        el: '#plataforma-website-banners',

        data: {
            banners: [],
            tipos: ['Simple', 'Detalle', 'Enlace'],
            crear: {
                titulo: '', tipo: '', detalle: '', url: ''
            },
            editar: {
                id: '', titulo: '', imagen: '', movil: '', tipo: '', detalle: '', url: '', estado: ''
            },
            temporal: {},
            eliminar: {
                _method: 'DELETE', id: ''
            },
        },

        created: function () {
            this.banners = banners;
        },

        computed: {
            bannersOrdenados: function () {
                return this.banners.sort(function (a, b) {
                    return parseInt(a.numero) - parseInt(b.numero);
                });
            },
        },

        methods: {
            crearBanner: function () {
                var self = this;

                var formData = new FormData();
                for (var propiedad in self.crear) {
                    formData.append(propiedad, self.crear[propiedad]);
                }
                formData.append('detalle', CKEDITOR.instances['txtCrearDetalle'].getData());
                formData.append('imagen', $('#txtCrearImagen')[0].files[0]);
                formData.append('movil', $('#txtCrearMovil')[0].files[0]);

                ajax_archivo('/plataforma/website/banners/almacenar', 'json', formData, $('#btnCrearBanner'), function (banner) {
                    self.banners.push(banner);
                    limpiarObjeto(self.crear);
                    $('#crearBannerModal').modal('hide');
                    CKEDITOR.instances['txtCrearDetalle'].setData('');
                    $('#txtCrearImagen').val('');
                    $('#txtCrearMovil').val('');
                    toastr.success('El banner "' + banner.titulo + '" fue creado satisfactoriamente.', 'BANNERS');
                });
            },

            actualizarEstado: function (banner) {
                banner.estado = parseInt(banner.estado) === 1 ? 0 : 1;
                var datos = {
                    _method: 'PUT', id: banner.id, estado: banner.estado
                };
                ajax('/plataforma/website/banners/actualizar_estado', 'html', datos, null, function () {
                    toastr.info('El estado del banner "' + banner.titulo + '" fue actualizado satisfactoriamente.', 'BANNERS');
                });
            },

            editarBanner: function (banner) {
                rellenarObjeto(this.editar, banner);
                this.temporal = banner;
                this.$refs.editarBannerTitulo.textContent = banner.titulo;
                CKEDITOR.instances['txtEditarDetalle'].setData(this.editar.detalle);
                $('#txtEditarImagen').val('');
                $('#txtEditarMovil').val('');
                $('#editarBannerModal').modal('show');
            },

            guardarBanner: function () {
                var self = this;

                var formData = new FormData();
                formData.append('_method', 'PUT');
                for (var propiedad in self.editar) {
                    formData.append(propiedad, self.editar[propiedad]);
                }
                formData.append('detalle', CKEDITOR.instances['txtEditarDetalle'].getData());
                formData.append('imagen', $('#txtEditarImagen')[0].files[0]);
                formData.append('movil', $('#txtEditarMovil')[0].files[0]);

                ajax_archivo('/plataforma/website/banners/actualizar', 'json', formData, $('#btnEditarBanner'), function (banner) {
                    rellenarObjeto(self.temporal, banner);
                    $('#editarBannerModal').modal('hide');
                    toastr.info('El banner "' + banner.titulo + '" fue actualizado satisfactoriamente.', 'BANNERS');
                });
            },

            eliminarBanner: function (event) {
                var self = this;
                if (confirm('¿Seguro(a) qué desea eliminar el banner "' + self.temporal.titulo + '"?')) {
                    self.eliminar.id = self.temporal.id;
                    ajax('/plataforma/website/banners/eliminar', 'json', self.eliminar, $(event.currentTarget), function (banners) {
                        self.banners = banners;
                        $('#editarBannerModal').modal('hide');
                        toastr.warning('El banner "' + self.temporal.titulo + '" ha sido eliminado.', 'BANNERS');
                    });
                }
            },
        }
    });

    $('#tblBanners tbody').sortable({
        helper: fixHelperModified,
        stop: function(event, ui) {
            ui.item.addClass('warning');
            var datos = { _method: 'PUT', lista: [] };
            $("#tblBanners tbody tr").each(function (index) {
                datos.lista.push($(this).data('banner-id'));
            });
            ajax('/plataforma/website/banners/ordenar', 'json', datos, null, function(banners) {
                ui.item.removeClass('warning');
                vmPlataformaWebsiteBanners.banners = banners;
                toastr.info('El orden de los banners fue actualizado satisfactoriamente.', 'BANNERS');
            });
        }
    }).disableSelection();

    CKEDITOR.replace('txtCrearDetalle');
    $('#crearBannerModal').on('shown.bs.modal', function () {
        $('#txtCrearTitulo').focus();
    });

    CKEDITOR.replace('txtEditarDetalle');
    $('#editarBannerModal').on('shown.bs.modal', function () {
        $('#txtEditarTitulo').focus();
    });

}
