if ($('#plataforma-website-uniformes-tiendas').length > 0) {

    var vmPlataformaWebsiteUniformesTiendas = new Vue({
        el: '#plataforma-website-uniformes-tiendas',

        data: {
            tiendas: [],
            crear: {
                nombre: '', direccion: '', telefonos: ''
            },
            editar: {
                _method: 'PUT', id: '', nombre: '', direccion: '', telefonos: ''
            },
            temporal: {},
            eliminar: {
                _method: 'DELETE', id: ''
            },
        },

        created: function () {
            this.tiendas = tiendas;
        },

        computed: {
            tiendasOrdenadas: function () {
                return this.tiendas.sort(function (a, b) {
                    return (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0);
                });
            },
        },

        methods: {
            crearTienda: function () {
                var self = this;
                ajax('/plataforma/website/uniformes/tiendas/almacenar', 'json', self.crear, $('#btnCrearTienda'), function (tienda) {
                    self.tiendas.push(tienda);
                    limpiarObjeto(self.crear);
                    $('#crearTiendaModal').modal('hide');
                    toastr.success('La tienda "' + tienda.nombre + '" fue creada satisfactoriamente.', 'UNIFORMES');
                });
            },

            editarTienda: function (tienda) {
                rellenarObjeto(this.editar, tienda, ['_method']);
                this.temporal = tienda;
                this.$refs.editarTiendaNombre.textContent = tienda.nombre;
                $('#editarTiendaModal').modal('show');
            },

            guardarTienda: function () {
                var self = this;
                ajax('/plataforma/website/uniformes/tiendas/actualizar', 'json', self.editar, $('#btnEditarTienda'), function (tienda) {
                    rellenarObjeto(self.temporal, tienda);
                    $('#editarTiendaModal').modal('hide');
                    toastr.info('La tienda "' + tienda.nombre + '" fue actualizada satisfactoriamente.', 'UNIFORMES');
                });
            },

            eliminarTienda: function (event) {
                var self = this;
                if (confirm('¿Seguro(a) qué desea eliminar la tienda "' + self.temporal.nombre + '"?')) {
                    self.eliminar.id = self.temporal.id;
                    ajax('/plataforma/website/uniformes/tiendas/eliminar', 'html', self.eliminar, $(event.currentTarget), function () {
                        toastr.warning('La tienda "' + self.temporal.nombre + '" ha sido eliminada.', 'UNIFORMES');
                        removerObjeto(self.tiendas, self.temporal);
                        $('#editarTiendaModal').modal('hide');
                    });
                }
            },
        }
    });

    $('#crearTiendaModal').on('shown.bs.modal', function () {
        $('#txtCrearTiendaNombre').focus();
    });

    $('#editarTiendaModal').on('shown.bs.modal', function () {
        $('#txtEditarTiendaNombre').focus();
    });

}
